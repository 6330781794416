@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z9lk0m');
  src:  url('fonts/icomoon.eot?z9lk0m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z9lk0m') format('truetype'),
    url('fonts/icomoon.woff?z9lk0m') format('woff'),
    url('fonts/icomoon.svg?z9lk0m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
  border-radius: 50%;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-parfums:before {
  content: "\e900";
}
.icon-jewerly:before {
  content: "\e901";
}
.icon-man:before {
  content: "\e902";
}
.icon-face:before {
  content: "\e903";
}
.icon-catalog:before {
  content: "\e904";
}
.icon-for-body:before {
  content: "\e905";
}
.icon-for-home:before {
  content: "\e906";
}
.icon-child:before {
  content: "\e907";
}
.icon-makeup:before {
  content: "\e908";
}
.icon-hair:before {
  content: "\e909";
}
.icon-health:before {
  content: "\e90a";
}

.hexagon {
  cursor: default;
  position: relative;
  width: 100px; 
  height: 57.73502691896258px;
  background-color: #ffffff;
  margin: 28.86751345948129px auto 0 auto;
  box-shadow: 0 0 10px rgba(238, 168, 77, 0.623);
  border-left: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  display: grid;
  transition: 0s;
}

.hexagon:before, .hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 70.71067811865474px;
  height: 70.71067811865474px;
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 12.64466094067263px;
  box-shadow: 0 0 10px rgba(238, 168, 77, 0.623);
  transition: 0s;
}

.hexagon:before {
  top: -35.35533905932737px;
  border-top: solid 2.8284271247461903px #ffffff;
  border-right: solid 2.8284271247461903px #ffffff;
}

.hexagon:after {
  bottom: -35.35533905932737px;
  border-bottom: solid 2.8284271247461903px #ffffff;
  border-left: solid 2.8284271247461903px #ffffff;
}

.hexagon div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 13px;
  color: #ffffff;
  top: 1.1547005383792515px;
  left: 0;
  width: 100%;
  height: 55.4256px;
  z-index: 2;
  background: inherit;
  transition: 0s;
}

.hidearr input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.hidearr input[type=number]{
  -moz-appearance: textfield;
}

/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
} */

/* .dev::after {
  content: '';
  position: absolute;
  width: auto;
  padding: 8px;
  border: 1px dashed #ccc !important;
  border-radius: 2px;
  
} */

.unknown{
  margin-top: 16px;
  padding: 8px;
  color: rgb(255, 0, 0)
}

.show-block .cc {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border: 1px dashed #3f9dff;  
}

.show-block .unknown, .selected{
  border: 1px dashed rgb(255, 0, 0) !important;  
}

.show-block .MuiGrid-container{  
  padding-top: 16px;
  
}

.builder .cc
{
  margin-top: 2px;
  margin-bottom: 2px;  
  border: 1px dashed transparent;
}

.builder .cc:hover
{
  border: 1px dashed #3f9dff !important;
}


.show-block .jr-carousel.cc, 
.show-block .jr-media.cc,
.show-block .jr-button.cc {
  padding-top: 16px;
}

.show-block .jr-carousel.cc,
.show-block .jr-media.cc,
.show-block img.cc,
.show-block div.cc,
.show-block p.cc,
.show-block h1.cc,
.show-block h2.cc,
.show-block h3.cc,
.show-block h4.cc,
.show-block h5.cc,
.show-block h6.cc {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAABgAAAAYADwa0LPAAAARVBMVEUAAAAAAP8AZswkbdsXdNEWeNIWddMYeNMadNIadtMZdtEZd9IZdtMadtIZdtIZd9IZdtIZdtMZdtIZdtIZdtIZdtIZdtKQO4YdAAAAFnRSTlMAAQUHISIjQERFe46bqaqutNrq7vj59ijyjQAAAAFiS0dEAIgFHUgAAABPSURBVAjXY2BAB2wcUMAK4rGKCkOBCIjPLgRTJsQO5zLycCJxmbi5eBBcJn4xboRiIE+AGcHlFeNnQjJKkI8JzWQ4l1UE7gwWsLNQHIkCAOwUBLRdcwQEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA5LTMwVDEwOjM0OjA5KzAwOjAwN4sL9QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wOS0zMFQxMDozNDowOSswMDowMEbWs0kAAAAASUVORK5CYII=') no-repeat;
  background-position: right 0px top 0px;  
}

.jr-carousel.cc.selected,
.jr-media.cc.selected,
img.cc.selected,
div.cc.selected,
p.cc.selected,
h1.cc.selected,
h2.cc.selected,
h3.cc.selected,
h4.cc.selected,
h5.cc.selected,
h6.cc.selected {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAABpklEQVR4AZWSA6weQRDHZ/aujl5Q23ajKnZS2zajxqzC2rYV1wpr246e3/tub3emt3Oo8f6X9fxGOYRYCBUTYwq9nLiwIyrbWqFi+E42Gl40iAkZ4FHTLSvvpZHw8dgZHapW8m+goXIAlmslMwMjR1CcECmvqg51j1Z7Nt713bsy5c05wJLGB7fXglS/lsCvhk38gp5qFu3viWOrNaAJU8N0qPrReDV07P77/UcMdGcwAVhdJnYxaAIk0j+AU5s2rXJp6OjN1ubeVasEQ+U+snG2MehkLQCFkGpO8+aVF3XtflKFuVFgdF6zw/tHSo1kxNbJj0ENwCqDFrRvfwpLi/sS0dW179/Ph1SuHCbJzIEooMUYbNH8GJeW9GUTnl/+5t3ATS9f6gRjqZEJs4jWGCSIPamgvJU14Zklbx8O3fG6QAuQyrqIVpxkqXL8js3PX2oFfxAJyFmNXBroF9U9qPG0V4/PoBRKXz0/Gh4gOiIyMhrABFVKrHkuERJYnevWpn0eqpY2+W089+G3NJmIC5me9r3+8IFzJb4dmAyUIcqg7/ecDPoK1+TKdkLaECEAAAAASUVORK5CYII=') no-repeat;
  background-position: right 0px top 0px;  
}

.sun-editor {
  border-radius: 4px;
}

.editor_custom {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;  
}
.editor_custom h1, .editor_custom h2, .editor_custom h3, .editor_custom h4, 
.editor_custom h5, .editor_custom h6 {
  margin: 0 0 0.35em 0;
}

.editor_custom h1.MuiTypography-h1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.167;
  letter-spacing: -0.01562em;
}
.editor_custom h2.MuiTypography-h2 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}
.editor_custom h3.MuiTypography-h3 { 
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
}
.editor_custom h4.MuiTypography-h4 {
  font-size: 2.125rem;  
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}
.editor_custom h5.MuiTypography-h5 {
  font-size: 1.5rem;  
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}
.editor_custom h6.MuiTypography-h6 {
  font-size: 1.25rem;  
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}


.CodeMirror {
  font-family: Menlo, Monaco, 'Courier New', monospace;
}
.sun-editor .se-wrapper .se-wrapper-code-mirror {
  font-size: 14px;
}
.liqpay{
  padding: 10px 0 !important;;
}

.fondy{  
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 65px;
    aspect-ratio: 1;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-shadow: 0 0 0 3px inset #fff;
    animation: l5 2.5s infinite;
  }
  .loader:after {
    animation-delay: -1.25s;
    border-radius: 0;
  }
  @keyframes l5{
    0%    {inset:0    35px 35px 0   }
    12.5% {inset:0    35px 0    0   }
    25%   {inset:35px 35px 0    0   }
    37.5% {inset:35px 0    0    0   }
    50%   {inset:35px 0    0    35px}
    62.5% {inset:0    0    0    35px}
    75%   {inset:0    0    35px 35px}
    87.5% {inset:0    0    35px 0   }
    100%  {inset:0    35px 35px 0   }
  }

 .rst__rowContents {
	padding-left: 8px !important;
    min-width: 400px !important;
}
/* .rst__moveHandle{
    width: 0 !important ;
    height: 0 !important;
} */

.vertical-align-middle {
    width: 16px !important;;  
    display: inline-flex;
    vertical-align: middle;
    margin-right: 16px;
    
}

.padding-bottom-3 {    
    padding-bottom: 3px;    
}
/* .grecaptcha-badge {	
	bottom:00px !important;
    right: 0px !important;
} */

#f{    
    height: auto !important;
    background-color: transparent !important;    
    box-shadow: none !important;
}

#f .f-container {
    box-shadow: none !important;
    border: solid 1px hsl(210, 13%, 91%) !important;
}
.f-header-logo {
    border: none !important;
}

/* #liqpay_checkout iframe:nth-child(even) {     */
#liqpay_checkout iframe {    
    border-radius: 16px !important;
    border: solid 1px #ddd !important;    
}