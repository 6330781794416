/* Load in your font */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.tree-wrapper {   
  height: 500px;
} 

/* .tree-wrapper {
  height: calc(100% - 25px);
} */


.tab{
  font-weight: 400;
}
.tab::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}
.menu-price{
  text-align: right;
  font-size: 1rem;
  display: inline-block;
  width: 66px !important;
  margin-right: 8px;
}

.rstcustom__collapseButton::after, 
.rstcustom__expandButton::after {
  border-top-color:#1876d2;   
}

.price--line-through{
  background-color: transparent;
  background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #f50606), color-stop(.52, transparent), color-stop(1, transparent));
  background-image: repeating-linear-gradient(170deg, transparent 0%, transparent 48%, #f50606 50%, transparent 52%, transparent 100%);
}
.text-gray{
  color: #616161;
}
.ReactVirtualized__Grid__innerScrollContainer { overflow: initial !important; }

.burst-8 {
  top: 8px;
  left: 10px;
  background: red;
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  transform: rotate(20deg);
}
.burst-8:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: red;
  transform: rotate(135deg);
}
